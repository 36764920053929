import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  starContainer: {
    display: "flex",
    gap: "0.1rem",
    alignContent: "center",
  },
  starLit: {
    color: colours.brightLightGreen,
    fontWeight: 700,
  },
  star: {
    fontSize: "1.5rem",
    color: "var(--color-neutral-d8)",
    fontWeight: 400,
    lineHeight: "32px",
    letterSpacing: "-0.04em",
    textAlign: "center",
    ...gStyles.fontRegular,

    [ScreenSizes.xlAndAbove]: {
      fontSize: "2.5rem",
    },
  },
};

export default function InsightAudienceReachIncome(props) {
  const { income } = props;

  const { styles, css } = useStyles(baseStyles, props);

  const litDollarSigns = income?.length;

  function incomeLevelDescription() {
    if (litDollarSigns >= 0 && litDollarSigns <= 5) {
      if (litDollarSigns === 0) {
        return "No income";
      } else if (litDollarSigns === 1) {
        return (
          <span>
            <b>$:</b> Low Income{" "}
          </span>
        );
      } else if (litDollarSigns === 2) {
        return (
          <span>
            <b>$$:</b> Below average income
          </span>
        );
      } else if (litDollarSigns === 3) {
        return (
          <span>
            <b>$$$:</b> Average income
          </span>
        );
      } else if (litDollarSigns === 4) {
        return (
          <span>
            <b>$$$$:</b> Above average income
          </span>
        );
      } else if (litDollarSigns === 5) {
        return (
          <span>
            <b>$$$$$:</b> High income
          </span>
        );
      }
    } else {
      return "Invalid value";
    }
  }

  return (
    <BasicTooltip renderTooltip={incomeLevelDescription}>
      {(tooltipProps) => (
        <section {...tooltipProps} className={css(styles.starContainer)}>
          <span
            data-testid="star1"
            className={css(styles.star, litDollarSigns >= 1 && styles.starLit)}
          >
            $
          </span>
          <span
            data-testid="star2"
            className={css(styles.star, litDollarSigns >= 2 && styles.starLit)}
          >
            $
          </span>
          <span
            data-testid="star3"
            className={css(styles.star, litDollarSigns > 2 && styles.starLit)}
          >
            $
          </span>
          <span
            data-testid="star4"
            className={css(styles.star, litDollarSigns > 3 && styles.starLit)}
          >
            $
          </span>
          <span
            data-testid="star5"
            className={css(styles.star, litDollarSigns > 4 && styles.starLit)}
          >
            $
          </span>
        </section>
      )}
    </BasicTooltip>
  );
}
