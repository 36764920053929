/* eslint-disable max-len */

export default {
  prefix: "fpc",
  iconName: "pc-male-female",
  icon: [
    512,
    512,
    [],
    null,
    "M138.667 0C174.013 0 202.667 28.654 202.667 64C202.667 99.346 174.013 128 138.667 128C103.321 128 74.6667 99.346 74.6667 64C74.6667 28.654 103.321 0 138.667 0ZM186.667 144H175.307C152.596 154.443 125.717 154.894 102.027 144H90.6667C64.1567 144 42.6667 165.49 42.6667 192V328C42.6667 341.255 53.4117 352 66.6667 352H82.6667V488C82.6667 501.255 93.4117 512 106.667 512H170.667C183.922 512 194.667 501.255 194.667 488V352H210.667C223.922 352 234.667 341.255 234.667 328V192C234.667 165.49 213.177 144 186.667 144Z M362.667 0C398.013 0 426.667 28.654 426.667 64C426.667 99.346 398.013 128 362.667 128C327.321 128 298.667 99.346 298.667 64C298.667 28.654 327.321 0 362.667 0ZM481.95 354.179L433.95 162.179C432.652 156.987 429.655 152.377 425.436 149.083C421.218 145.789 416.019 144 410.667 144H399.307C376.596 154.443 349.717 154.894 326.027 144H314.667C309.314 144 304.116 145.789 299.897 149.083C295.678 152.377 292.682 156.987 291.384 162.179L243.384 354.179C239.602 369.305 251.05 384 266.667 384H322.667V488C322.667 501.255 333.412 512 346.667 512H378.667C391.922 512 402.667 501.255 402.667 488V384H458.667C474.258 384 485.738 369.329 481.95 354.179Z",
  ],
};
