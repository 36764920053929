import { faFlag } from "@fortawesome/free-regular-svg-icons/faFlag";
import { faFlag as flagged } from "@fortawesome/free-solid-svg-icons/faFlag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback } from "react";
import {
  flagDemographicsPromise,
  unflagDemographicsPromise,
} from "routines/podcast";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import sendGAEvent from "utils/sendGAEvent";

import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  icon: {
    fontSize: "0.813rem",
    color: "var(--color-neutral-d5)",
  },
  flagText: {
    margin: 0,
    ...gStyles.avalonBold,
    color: "var(--color-neutral-d5)",
    fontSize: "0.813rem",
  },
  flagButton: {
    display: "flex",
    alignItems: "center",
    border: "none",
    outline: "none",
    marginRight: 11,

    marginBottom: "0.75rem",

    [ScreenSizes.lgAndAbove]: {
      marginBottom: "1rem",
    },

    [ScreenSizes.mdAndAbove]: {
      marginRight: 0,
    },

    ":hover": {
      cursor: "pointer",
    },
  },
};

const FLAG_CODE = "podcast_demographics_incorrect";

export default function FlagDemographics(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { podcast } = props;

  const { flagDemographics, unflagDemographics } = useRoutinePromises({
    flagDemographics: flagDemographicsPromise,
    unflagDemographics: unflagDemographicsPromise,
  });

  let isFlagged = false;

  const podcastId = podcast?.get("id");
  const flags = podcast?.getIn(["user_data", "flags"]);

  if (flags) {
    flags.forEach((flag) => {
      const code = flag?.getIn(["flag_type", "code"]);
      if (code === FLAG_CODE) {
        isFlagged = true;
      }
    });
  }

  const handleFlag = useCallback(() => {
    if (isFlagged) {
      unflagDemographics({ podcastId, flag_code: FLAG_CODE }).catch((error) => {
        console.log("Failed to flag", { error });
      });
      sendGAEvent({
        action: "Un-Flag Podcast Demographic Button Clicked",
        podcast_id: podcast.get("id"),
        podcast_name: podcast.get("title"),
        context: "Podcast Insights",
      });
    } else {
      sendGAEvent({
        action: "Flag Podcast Demographic Button Clicked",
        podcast_id: podcast.get("id"),
        podcast_name: podcast.get("title"),
        context: "Podcast Insights",
      });

      flagDemographics({ podcastId, flag_code: FLAG_CODE }).catch((error) => {
        console.log("Failed to undo flag", { error });
      });
    }
  }, [flagDemographics, isFlagged, podcastId, podcast, unflagDemographics]);

  return (
    <BasicTooltip
      renderTooltip={() => (
        <span>
          {isFlagged
            ? "You flagged these reach numbers for an additional quality check."
            : "Something look fishy? Flag this data for a second look!"}
        </span>
      )}
    >
      {(tooltipProps) => (
        <button
          data-testid="actionButton"
          className={css(styles.flagButton)}
          onClick={handleFlag}
          {...tooltipProps}
        >
          <FontAwesomeIcon
            className={css(styles.icon)}
            icon={isFlagged ? flagged : faFlag}
            dataid={isFlagged ? "flaged" : "unFlagged"}
          />
        </button>
      )}
    </BasicTooltip>
  );
}

FlagDemographics.propTypes = {
  podcast: PropTypes.instanceOf(Map),
};
