import Loadable from "@loadable/component";
import { css } from "aphrodite";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    ...gStyles.skeletonPlaceholder,
    width: "100%",
    height: "14rem",

    [ScreenSizes.mdAndAbove]: {
      height: "14rem",
    },
  },
};

const InsightsSecondaryDemographics = Loadable(() =>
  import("./InsightsSecondaryDemographics")
);

function InsightsSecondaryDemographicsAsyncLoading(props) {
  const { styles } = useStyles(baseStyles, props);

  return (
    <>
      <div className={css(styles.container)} />
    </>
  );
}

const InsightsSecondaryDemographicsContainer = (props) => {
  return (
    <InsightsSecondaryDemographics
      {...props}
      fallback={<InsightsSecondaryDemographicsAsyncLoading />}
    />
  );
};

export default InsightsSecondaryDemographicsContainer;
