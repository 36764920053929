import PropTypes from "prop-types";
import React from "react";

import InsightsColorLabel from "./InsightsColorLabel";

import { capitalizeWords } from "utils/case";

import colours from "styles/colours";

const femaleGenderSkewStyles = {
  color: colours.brightBlue,
  background: colours.fadedLightBlue,
};

const maleGenderSkewStyles = {
  color: colours.darkPurple,
  background: colours.darkPurpleForeground,
};

const mixedGenderSkewStyles = {
  color: "var(--color-neutral-d5)",
  background: "var(--color-neutral-d9)",
};

function getDemographicSkewLabel({ genderScore, value }) {
  return genderScore >= 50 && genderScore <= 55
    ? "Mixed"
    : capitalizeWords(value);
}

export default function InsightsGenderColorLabel(props) {
  const { genderScore, genderValue } = props;

  const labelStyle =
    genderScore >= 50 && genderScore <= 54.99
      ? mixedGenderSkewStyles
      : genderValue === "male"
      ? maleGenderSkewStyles
      : femaleGenderSkewStyles;

  return (
    <InsightsColorLabel
      labelStyle={labelStyle}
      {...props}
      label={getDemographicSkewLabel({
        genderScore,
        value: genderValue,
      })}
    />
  );
}

InsightsGenderColorLabel.propTypes = {
  label: PropTypes.string,
  labelStyle: PropTypes.object,
};
