import { faArrowUpRightFromSquare } from "@fortawesome/pro-duotone-svg-icons/faArrowUpRightFromSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback } from "react";

import ReachContentHelpIcon from "pages/PodcastView/Insights/Sections/insightReach/ReachContentHelpIcon";
import ReachPopUp from "pages/PodcastView/Insights/Sections/insightReach/ReachPopUp";

import sendGAEvent from "utils/sendGAEvent";

import useOutsideClickHandler from "hooks/useOutsideClickHandler";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  para: {
    ...gStyles.fontRegular,
    fontSize: "0.813rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  paraBold: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  link: {
    ...gStyles.fontBold,
    fontSize: "0.813rem",
    color: colours.primary,
    textAlign: "center",
    margin: "0 0 0 0.188rem",

    ":hover": {
      cursor: "pointer",
    },
  },
  popUpTitle: {
    ...gStyles.avalonBold,
    fontSize: "1.25rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  button: {
    width: "max-content",
    border: `1px solid ${colours.borderGrey}`,
    padding: "0.5rem 1rem",
    borderRadius: 25,
    ...gStyles.avalonBold,
    fontSize: "1rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: "1rem auto 0 auto",
    cursor: "pointer",
  },

  title: {
    ...gStyles.avalonBold,
    fontSize: "0.75rem",
    color: "var(--color-neutral-d5)",
    textAlign: "center",
    margin: 0,
  },
};

const InsightsDemographicsInfoPopUp = (props) => {
  const {
    title,
    info,
    podcast,
    showPopUp,
    handlePopUp,
    popUpStyles,
    helpIconStyles,
  } = props;
  const { styles, css } = useStyles(baseStyles, props);

  let activelySelected = showPopUp?.active;

  const { getWindowWidth } = useWindowSize();

  const currentSize = getWindowWidth();
  const onMobile = currentSize < 640;

  const onClick = useCallback(
    (item) => {
      handlePopUp(item);

      sendGAEvent({
        action: "infoIconClick",
        context: "Demographic Insights",
        element: title,
        page: "Podcast Insights",
        podcastId: podcast.get("id"),
      });
    },
    [handlePopUp, podcast, title]
  );

  const wrapperRef = useOutsideClickHandler(() => handlePopUp(null));
  return (
    <ReachContentHelpIcon
      onClick={() => onClick(title)}
      show={activelySelected === title}
      styles={helpIconStyles}
    >
      {activelySelected === title && (
        <ReachPopUp styles={popUpStyles}>
          <h2 className={css(styles.popUpTitle)}>{title}</h2>
          <p className={css(styles.para)}>
            {info} Please contact the podcast for precise demographics.
          </p>

          <>
            {onMobile ? (
              <a
                className={css(styles.paraBold)}
                href="https://support.podchaser.com/en/articles/8672076-demographics-and-reach"
                target="_blank"
                rel="noreferrer"
              >
                Learn more &nbsp;&nbsp;
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  dataid="expand"
                />
              </a>
            ) : (
              <p className={css(styles.para)}>
                Learn more about
                <a
                  ref={wrapperRef}
                  className={css(styles.link)}
                  href="https://support.podchaser.com/en/articles/8672076-demographics-and-reach"
                  target="_blank"
                  rel="noreferrer"
                  data-testid="learn-more-href"
                >
                  demographics
                </a>
              </p>
            )}
          </>

          {onMobile && (
            <button
              onClick={() => onClick(title)}
              className={css(styles.button)}
            >
              Got It
            </button>
          )}
        </ReachPopUp>
      )}
    </ReachContentHelpIcon>
  );
};

InsightsDemographicsInfoPopUp.propTypes = {
  title: PropTypes.string,
  info: PropTypes.string,
  podcast: PropTypes.instanceOf(Map),
  showPopUp: PropTypes.object,
  handlePopUp: PropTypes.func,
  popUpStyles: PropTypes.object,
  helpIconStyles: PropTypes.object,
};

export default InsightsDemographicsInfoPopUp;
