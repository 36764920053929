import PropTypes from "prop-types";
import React from "react";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";

const baseStyles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: "-0.5rem",
    gap: "0.75rem",
    alignItems: "center",
  },
  loaderContainer: {
    width: "100%",
    background: colours.darkishBlue,
    minWidth: "98px",
    top: "64px",
    left: "30.75px",
    borderRadius: "0.125rem",
    display: "flex",
    overflow: "hidden",
    maxWidth: "6.125rem",
    height: "0.75rem",
  },
  loader: {
    height: "100%",
  },
  seperator: {
    width: "0.1875rem",
    height: "0.75rem",
    background: colours.white,
  },
};

export default function InsightsColorLoader(props) {
  const { withSeparator, title, label, background, score } = props;
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <BasicTooltip renderTooltip={() => title}>
      {(tooltipProps) => (
        <div {...tooltipProps} className={css(styles.wrapper)}>
          {label}
          <div
            data-testid="loader-container"
            className={css(styles.loaderContainer)}
          >
            <div
              data-testid="loader"
              className={css(styles.loader)}
              style={{
                width: `calc(${score}% - 0.1875rem)`,
                background: background,
              }}
            />
            {withSeparator && <div className={css(styles.seperator)} />}
          </div>
        </div>
      )}
    </BasicTooltip>
  );
}

InsightsColorLoader.propTypes = {
  background: PropTypes.string,
  label: PropTypes.string,
  score: PropTypes.number,
  title: PropTypes.node,
  withSeparator: PropTypes.bool,
};
