import { Map } from "immutable";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getUrlFiltersFromJS } from "utils/search";
import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  title: {
    cursor: "pointer",
  },
};

const DemographicFilterTitle = (props) => {
  const {
    title,
    filterValue: value,
    filter,
    filters: activeFilters,
    podcast,
  } = props;

  const { css, styles } = useStyles(baseStyles, props);
  const [filters, setFilters] = useState(activeFilters);
  const [filterValue, setFilterValue] = useState("");

  useEffect(() => {
    let updatedFilters = filters;
    const key = filter?.get("key");

    updatedFilters = updatedFilters.set(key, filter?.set("value", [value]));
    const calculatedFilterValue = getUrlFiltersFromJS(updatedFilters);
    setFilterValue(calculatedFilterValue);
  }, [value, filter, filters]);

  const handleMouseDown = () => {
    // Need this, if we don't add mouseDown it will also open filters from previous podcast filter click
    setFilters(Map({}));

    sendGAEvent({
      action: "openedNewSerchDemographicClick",
      context: "DemographicClcik",
      podcastTitle: podcast?.get("title"),
      podcastId: podcast?.get("id"),
    });
  };

  return (
    <>
      {filter ? (
        <Link
          to={`/podcasts?filter=${filterValue}`}
          className={css(styles.title)}
          target="_blank"
          onMouseDown={handleMouseDown}
        >
          {title}
        </Link>
      ) : (
        <span>{title}</span>
      )}
    </>
  );
};

DemographicFilterTitle.propTypes = {
  title: PropTypes.string,
  filter: PropTypes.instanceOf(Map),
  filters: PropTypes.object,
  filterValue: PropTypes.string,
  podcast: PropTypes.instanceOf(Map),
};

export default DemographicFilterTitle;
