import PropTypes from "prop-types";
import React from "react";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  label: {
    ...gStyles.avalonBold,

    borderRadius: "6.25rem",
    padding: "0.25rem 0.75rem 0.25rem 0.75rem",
    minHeight: "1.5rem",
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    lineHeight: "1.25rem",
    textAlign: "center",
    justifyContent: "center",
  },
};

export default function InsightsColorLabel(props) {
  const { labelStyle, label } = props;
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div style={labelStyle} className={css(styles.label)}>
      {label}
    </div>
  );
}

InsightsColorLabel.propTypes = {
  label: PropTypes.string,
  labelStyle: PropTypes.object,
};
